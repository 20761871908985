<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path
      d="M24,12A12,12,0,1,0,9.1,23.647v-7.98H6.627V12H9.1V10.42c0-4.084,1.848-5.978,5.858-5.978a13.231,13.231,0,0,1,2.609.3V8.065c-.283-.03-.775-.045-1.386-.045-1.968,0-2.728.745-2.728,2.683V12h3.92L16.7,15.667H13.454v8.245A12,12,0,0,0,24,12"
      fill="currentColor"
    />
  </svg>
</template>
